<template>

  <div class="sign-wrapper">
    <div class="sign">

      <h1 class="sign__title">New Password</h1>

      <form class="sign__form" @submit.prevent="setPassword">

        <input
          v-model="password"
          type="password"
          class="sign__input"
          placeholder="Password"
          autocomplete="new-password"
        />

        <div v-if="$v.password.$error">
          <small v-if="!$v.password.required" class="sign__form-error sign__form-error_tip">
            The password field is empty. Please enter a strong password.
          </small>

          <small v-if="!$v.password.minLength" class="sign__form-error sign__form-error_tip">
            Password must contain at least 8 characters.
          </small>

          <small v-if="!$v.password.passwordIsValid" class="sign__form-error sign__form-error_tip">
            Please, read password recommendations below
          </small>
        </div>

        <p class="sign__tip">
          Use 8 or more characters with a mix of letters, numbers & symbols.
          <b-link class="sign__tip-link" id="password-tip">How to make a strong password</b-link>
        </p>
        <b-tooltip tabindex="-1" target="password-tip" triggers="hover" placement="bottomright">
          <ul class="sign__tip-list">
            <li
              :class="{'sign__tip-list-item_green': passwordParams.hasAtLeastOneLowercase}"
              class="sign__tip-list-item">
              <span>Have at least one lower case character</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.hasAtLeastOneCapital}"
              class="sign__tip-list-item">
              <span>Have at least one capital letter</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.hasNumbers}"
              class="sign__tip-list-item">
              <span>Have at least one number</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.sameCharacters}"
              class="sign__tip-list-item">
              <span>Your password must not contain more than 2 consecutive identical characters</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.latinCharacters}"
              class="sign__tip-list-item">
              <span>Your password must contain only Latin letters</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.minLength}"
              class="sign__tip-list-item">
              <span>Be at least 8 characters</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.isCommonPassword}"
              class="sign__tip-list-item">
              <span>Not be a common password</span>
            </li>
          </ul>
        </b-tooltip>

        <input
          v-model="passwordConfirmation"
          type="password"
          class="sign__input"
          placeholder="Password confirmation"
          autocomplete="new-password"
        />

        <small v-if="message" class="sign__form-error">{{ message }}</small>

        <div v-if="$v.passwordConfirmation.$error">
          <small
            v-if="!$v.passwordConfirmation.sameAsPassword"
            class="sign__form-error"
          >
            Password and confirm password do not match
          </small>
        </div>

        <div class="sign__submit-wrapper">
          <button v-if="!loading" class="sign__submit">
            Set a password
          </button>

          <b-spinner v-else variant="primary"></b-spinner>
        </div>

        <p class="sign__forgot-links">
          <router-link class="entry-link" :to="{ name: 'sign-up' }">Sign up</router-link>
          or
          <router-link class="entry-link" :to="{ name: 'sign-in' }">Login</router-link>
        </p>
      </form>

    </div>
  </div>
</template>

<script>
  import {required, sameAs, minLength, email} from 'vuelidate/lib/validators'
  import {CHANGE_PASSWORD_BY_TOKEN, MODULE_NAME as AUTH} from '@/store/modules/auth'
  import {top200Passwords} from "@/utils";

  export default {
    name: 'NewPassword',
    components: {},
    data() {
      return {
        password: '',
        passwordConfirmation: '',
        token: '',
        message: ''
      }
    },
    validations: {
      password: {
        required,
        minLength: minLength(8),
        passwordIsValid: (v, vm) => vm.passwordParams.allFieldsIsValid,
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    },
    computed: {
      loading() {
        return this.$store.state[AUTH].changePasswordByToken.loading
      },
      passwordParams() {
        const isNotEmpty = !!this.password

        const params = {
          minLength: isNotEmpty && this.$v.password.minLength,
          hasNumbers: !!this.password.match(/[0-9]/ig),
          hasAtLeastOneCapital: this.password.match(/[A-Z]/),
          hasAtLeastOneLowercase: this.password.match(/[a-z]/),
          sameCharacters: isNotEmpty && this.checkSameCharacters(this.password),
          isCommonPassword: isNotEmpty && !top200Passwords.includes(this.password),
          latinCharacters: this.password.match(/[a-zA-Z]/)
        }

        return {
          ...params,
          allFieldsIsValid: Object.keys(params).every(item => params[item])
        }
      }
    },
    created() {
      this.token = this.$route.query.token
    },
    methods: {
      async setPassword() {
        if (this.loading) {
          return
        }

        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        await this.$store.dispatch(CHANGE_PASSWORD_BY_TOKEN, {
          token: this.token,
          password: this.password
        })

        this.$router.push({name: 'sign-in'})
      },
      checkSameCharacters(str) {
        return !/(.)\1/.test(str);
      },
    }
  }
</script>
